import { defineAsyncComponent } from 'vue';
import AsyncComponentWrapper from '../AsyncComponentWrapper.vue';

export default defineAsyncComponent({
  loader: () => import(
    /* webpackChunkName: "badge-award-modal" */
    '../BadgeAwardModal.vue'
  ),
  loadingComponent: AsyncComponentWrapper,
  delay: 200, // Show loading component after 200ms
  timeout: 5000, // Timeout after 5s
  errorComponent: {
    template: `
      <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong class="font-bold">Error!</strong>
        <span class="block sm:inline">Failed to load badge award modal</span>
      </div>
    `
  },
  onError(error, retry, fail) {
    // Log error and allow retry
    console.error('Error loading BadgeAwardModal:', error);
    fail();
  }
}); 