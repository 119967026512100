import { AppRouteRecord } from '../types';

const notificationRoutes: AppRouteRecord[] = [
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/pages/NotificationsPage.vue'),
    meta: { requiresAuth: true }
  }
];

export default notificationRoutes; 