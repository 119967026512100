import { createPinia } from 'pinia'
import { logger } from '@/utils/logger';

// Create pinia instance
const pinia = createPinia()

// Add store initialization hook
pinia.use(({ store }) => {
  store.$onAction(({ name, store }) => {
    logger.debug(`Store action called: ${name}`, {
      module: `Store:${store.$id}`,
      metadata: { action: name }
    });
  })

  logger.debug('Initializing store', {
    module: 'Store',
    metadata: { storeId: store.$id }
  });
})

// Initialize auth store
try {
  logger.debug('Initializing auth store', { module: 'Store' });
  
  const token = localStorage.getItem('token')
  if (token) {
    logger.debug('Found token, attempting to restore session', { module: 'Store' });
  }
} catch (error) {
  logger.error('Error initializing auth store', {
    module: 'Store',
    metadata: { error }
  });
}

export default pinia;

// Export individual stores
export { useAuthStore } from './auth'
export { useUserStore } from './user'
export { useGoogleStore } from './google'
export { useNotificationsStore } from './notifications'
export { usePasscodeStore } from './passcode' 