import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { isDebugMode } from '@/config'
import api from '@/utils/api'
import type { User, ScheduleData } from '@/types/user'
import { useAuthStore } from '@/stores/auth'

export const useUserStore = defineStore('user', () => {
  // State
  const user = ref<User | null>(null);
  const profilePictureUrl = ref<string | null>(null);
  const isLoading = ref(false);
  const error = ref<string | null>(null);

  // Getters
  const currentUser = computed(() => user.value);
  const getProfilePictureUrl = computed(() => 
    profilePictureUrl.value || 'https://via.placeholder.com/150'
  );

  // Reset state
  const resetState = () => {
    user.value = null;
    profilePictureUrl.value = null;
    isLoading.value = false;
    error.value = null;
  };

  // Add a utility function for converting working days
  const parseWorkingDays = (workingDays: any): number[] | undefined => {
    if (isDebugMode()) {
      console.log('[User] parseWorkingDays input:', workingDays, 'type:', typeof workingDays);
    }

    if (!workingDays) return undefined;
    
    try {
      // If it's a string with brackets, it might be a stringified array
      if (typeof workingDays === 'string' && workingDays.includes('[')) {
        try {
          const parsed = JSON.parse(workingDays);
          if (Array.isArray(parsed)) {
            const result = parsed.map(Number).filter(n => !isNaN(n) && n >= 1 && n <= 7);
            if (isDebugMode()) {
              console.log('[User] Parsed JSON string array:', result);
            }
            return result.length > 0 ? result : undefined;
          }
        } catch (e) {
          console.error('[User] Error parsing JSON string:', e);
        }
      }

      // Handle regular comma-separated string
      if (typeof workingDays === 'string') {
        const result = workingDays.split(',')
          .map(s => s.trim())
          .map(Number)
          .filter(n => !isNaN(n) && n >= 1 && n <= 7);
        if (isDebugMode()) {
          console.log('[User] Parsed comma-separated string:', result);
        }
        return result.length > 0 ? result : undefined;
      }
      
      // Handle array input
      if (Array.isArray(workingDays)) {
        const result = workingDays.map(Number).filter(n => !isNaN(n) && n >= 1 && n <= 7);
        if (isDebugMode()) {
          console.log('[User] Parsed array input:', result);
        }
        return result.length > 0 ? result : undefined;
      }
      
      if (isDebugMode()) {
        console.log('[User] Invalid working days format:', workingDays);
      }
      return undefined;
    } catch (err) {
      console.error('[User] Error parsing working days:', err);
      return undefined;
    }
  };

  // Actions
  const setUser = async (userData: User | null) => {
    if (isDebugMode()) {
      console.log('[User] Action: setUser called with data:', userData ? 'Present' : 'Null');
    }
    try {
      if (!userData) {
        user.value = null;
        return true;
      }

      if (!userData.id || !userData.email) {
        console.error('[User] Invalid user data received:', userData);
        throw new Error('Invalid user data format');
      }
      
      // Always fetch fresh user data from the backend
      try {
        const response = await api.get('/auth/me');
        if (response.data) {
          // Ensure the response data has all required User properties
          const processedData = response.data as User;
          if (processedData.id && processedData.email) {
            if (isDebugMode()) {
              console.log('[User] Raw response data:', response.data);
              console.log('[User] Raw working days from response:', processedData.working_days);
            }
            // Set the user data from the fresh response
            user.value = {
              ...processedData,
              working_days: parseWorkingDays(processedData.working_days),
              total_system_points: processedData.total_system_points || 0,
              list_profile_under_browse: processedData.list_profile_under_browse === true
            };
            
            if (isDebugMode()) {
              console.log('[User] User data set successfully:', user.value);
              console.log('[User] Working days processed:', user.value.working_days);
            }
            return true;
          }
        }
      } catch (err) {
        console.error('[User] Error fetching processed user data:', err);
        // If we fail to fetch fresh data, fall back to the provided userData
        user.value = {
          ...userData,
          working_days: parseWorkingDays(userData.working_days),
          total_system_points: userData.total_system_points || 0,
          list_profile_under_browse: userData.list_profile_under_browse === true
        };
      }
      
      return true;
    } catch (err) {
      console.error('[User] Error in setUser action:', err);
      throw err;
    }
  };

  const refreshUser = async () => {
    if (isDebugMode()) {
      console.log('[User] Refreshing user data');
    }
    try {
      isLoading.value = true;
      const response = await api.get('/auth/me');
      if (response.data) {
        if (isDebugMode()) {
          console.log('[User] Raw API response:', response.data);
          console.log('[User] Raw working days:', response.data.working_days);
        }
        const processedData = response.data as User;
        if (processedData.id && processedData.email) {
          // Parse working days before setting user data
          const parsedWorkingDays = parseWorkingDays(processedData.working_days);
          if (isDebugMode()) {
            console.log('[User] Parsed working days:', parsedWorkingDays);
          }

          user.value = {
            ...processedData,
            working_days: parsedWorkingDays,
            total_system_points: processedData.total_system_points || 0,
            list_profile_under_browse: processedData.list_profile_under_browse === true
          };
          if (isDebugMode()) {
            console.log('[User] User data refreshed:', user.value);
            console.log('[User] Final working days:', user.value.working_days);
          }
          return true;
        }
      }
      throw new Error('Invalid user data received from refresh');
    } catch (err) {
      console.error('[User] Error refreshing user data:', err);
      throw err;
    } finally {
      isLoading.value = false;
    }
  };

  const clearUser = () => {
    resetState();
    return true;
  };

  const updateWorkingSchedule = async (scheduleData: ScheduleData) => {
    if (isDebugMode()) {
      console.log('[User] Updating working schedule:', scheduleData);
    }
    try {
      isLoading.value = true;

      // Ensure time is in HH:MM format
      const formatTime = (time: string) => {
        if (!time.includes(':')) return time;
        // If time includes seconds (HH:MM:SS), remove them
        return time.split(':').slice(0, 2).join(':');
      };

      // Sort and convert working days to string for API
      const workingDaysString = [...scheduleData.working_days]
        .sort((a, b) => a - b)
        .join(',');

      const payload = {
        working_days: workingDaysString,
        working_hours_start: formatTime(scheduleData.working_hours_start),
        working_hours_end: formatTime(scheduleData.working_hours_end),
        timezone: scheduleData.timezone
      };

      if (isDebugMode()) {
        console.log('[User] Sending payload:', payload);
      }

      const response = await api.put('/users/me', payload);
      
      if (user.value && response.data) {
        user.value = {
          ...user.value,
          working_days: parseWorkingDays(workingDaysString),
          working_hours_start: payload.working_hours_start,
          working_hours_end: payload.working_hours_end,
          timezone: payload.timezone
        };

        if (isDebugMode()) {
          console.log('[User] Updated working days:', user.value.working_days);
        }
      }
      return true;
    } catch (err) {
      error.value = err instanceof Error ? err.message : 'Failed to update schedule';
      throw err;
    } finally {
      isLoading.value = false;
    }
  };

  const updateProfile = async (profileData: Partial<User>) => {
    if (isDebugMode()) {
      console.log('[User] Updating profile with data:', profileData);
    }
    
    const authStore = useAuthStore();
    const currentToken = authStore.token;
    
    // Store original state for reversion
    const originalState = user.value ? { ...user.value } : null;
    
    try {
      isLoading.value = true;
      error.value = null;
      
      // Update local state immediately for optimistic UI
      if (user.value) {
        user.value = {
          ...user.value,
          ...profileData,
          // Ensure boolean values are properly cast
          list_profile_under_browse: profileData.list_profile_under_browse === true,
          // Only include working_days if it's being updated
          ...(profileData.working_days ? { working_days: parseWorkingDays(profileData.working_days) } : {})
        };
      }
      
      const response = await api.put('/users/me', profileData);
      
      if (response.data) {
        if (isDebugMode()) {
          console.log('[User] Profile update response:', response.data);
        }
        
        // Update with server response
        user.value = {
          ...user.value,
          ...response.data,
          // Ensure boolean values are properly cast
          list_profile_under_browse: response.data.list_profile_under_browse === true,
          // Only include working_days if it's in the response
          ...(response.data.working_days ? { working_days: parseWorkingDays(response.data.working_days) } : {})
        };
        
        if (isDebugMode()) {
          console.log('[User] Updated user state:', user.value);
        }
      }
      return true;
    } catch (err) {
      if (isDebugMode()) {
        console.error('[User] Profile update error:', err);
      }
      error.value = err instanceof Error ? err.message : 'Failed to update profile';
      
      // Check for auth error in the original error
      const isAuthError = err instanceof Error && 
        (err.message.includes('401') || 
         err.message.includes('403') || 
         err.message.includes('authentication') ||
         err.message.includes('Unauthorized'));

      if (isAuthError) {
        await authStore.cleanLogout();
        user.value = null;
        throw err;
      }
      
      // Revert to original state on error
      if (originalState) {
        user.value = { ...originalState };
      }
      
      throw err;
    } finally {
      isLoading.value = false;
    }
  };

  const deleteAccount = async (token: string) => {
    if (isDebugMode()) {
      console.log('[User] Deleting account');
    }
    try {
      isLoading.value = true;
      const response = await api.delete('/users/me', { data: { token } });
      
      if (response) {
        resetState();
        return true;
      }
      
      error.value = 'Failed to delete account';
      throw new Error('Failed to delete account');
    } catch (err) {
      if (isDebugMode()) {
        console.error('[User] Error deleting account:', err);
      }
      error.value = err instanceof Error ? err.message : 'Failed to delete account';
      throw err;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    // State
    user,
    profilePictureUrl,
    isLoading,
    error,

    // Getters
    currentUser,
    getProfilePictureUrl,

    // Actions
    setUser,
    refreshUser,
    clearUser,
    updateWorkingSchedule,
    updateProfile,
    deleteAccount
  };
}); 