import { AppRouteRecord } from '../types';
import { isDebugMode } from '@/config';

// Conditionally include dev routes based on debug mode
const debugStatus = isDebugMode();
const appEnv = import.meta.env.VITE_APP_ENV;
console.log('Route Registration Debug:', {
  debugStatus,
  appEnv,
  VITE_DEBUG_MODE: import.meta.env.VITE_DEBUG_MODE,
  typeof_DEBUG_MODE: typeof import.meta.env.VITE_DEBUG_MODE
});

const authRoutes: AppRouteRecord[] = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(
      /* webpackChunkName: "auth-login" */
      /* webpackPrefetch: true */
      '@/pages/LoginPage.vue'
    )
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(
      /* webpackChunkName: "auth-signup" */
      /* webpackPrefetch: true */
      '@/pages/SignupPage.vue'
    )
  },
  {
    path: '/passcode',
    name: 'Passcode',
    component: () => import(
      /* webpackChunkName: "auth-passcode" */
      '@/pages/PasscodePage.vue'
    ),
    meta: { requiresAuth: false }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(
      /* webpackChunkName: "auth-forgot-password" */
      '@/pages/ForgotPasswordPage.vue'
    ),
    meta: { 
      requiresAuth: false,
      blockDuringLogin: true
    }
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import(
      /* webpackChunkName: "auth-verify-email" */
      '@/pages/VerifyEmailPage.vue'
    )
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(
      /* webpackChunkName: "auth-reset-password" */
      '@/pages/ResetPasswordPage.vue'
    )
  },
  {
    path: '/auth/google/callback',
    name: 'GoogleAuthCallback',
    component: () => import(
      /* webpackChunkName: "auth-google-callback" */
      '@/pages/GoogleAuthCallback.vue'
    ),
    meta: { requiresAuth: false }
  },
  {
    path: '/google-calendar-callback',
    name: 'GoogleCalendarCallback',
    component: () => import(
      /* webpackChunkName: "auth-google-calendar-callback" */
      '@/pages/GoogleCalendarCallback.vue'
    )
  }
];

export default authRoutes;          