import { AppRouteRecord } from '../types';

const settingRoutes: AppRouteRecord[] = [
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../../pages/SettingsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/settings/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../../pages/PublicProfilePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/google-calendar-callback',
    name: 'GoogleCalendarCallback',
    component: () => import(/* webpackChunkName: "google-calendar" */ '../../pages/GoogleCalendarCallback.vue'),
    meta: {
      requiresAuth: true
    }
  }
];

export default settingRoutes; 