import { AppRouteRecord } from '../types';

const waitlistRoutes: AppRouteRecord[] = [
  {
    path: '/waitlisted',
    name: 'Waitlisted',
    component: () => import('@/pages/WaitlistedPage.vue'),
    meta: { 
      requiresAuth: false,
      title: 'Thanks for Joining Our Waitlist'
    }
  }
];

export default waitlistRoutes; 