import { logger } from '@/utils/logger';

export type Environment = 'production' | 'staging' | 'local';

export const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:8000';

export const isDebugMode = () => {
  const env = (import.meta.env.VITE_APP_ENV || 'local').toLowerCase() as Environment;
  return env !== 'production';
};

export const getGoogleClientId = (): string => {
  const env = (import.meta.env.VITE_APP_ENV || 'local').toLowerCase() as Environment;
  let clientId = '';

  switch (env) {
    case 'production':
      clientId = import.meta.env.VITE_GCAL_CLIENT_ID_PROD;
      break;
    case 'staging':
      clientId = import.meta.env.VITE_GCAL_CLIENT_ID_STAGING;
      break;
    default:
      clientId = import.meta.env.VITE_GCAL_CLIENT_ID_LOCAL;
  }

  if (!clientId) {
    logger.error('Google Calendar client ID not found', {
      module: 'Config',
      metadata: {
        environment: env,
        availableEnvVars: import.meta.env
      }
    });
    throw new Error(`Google Calendar client ID not configured for environment: ${env}`);
  }

  return clientId;
};

export const ORIGIN: string = (() => {
  const env = (import.meta.env.VITE_APP_ENV || 'local').toLowerCase() as Environment;
  switch (env) {
    case 'production':
      return 'https://justbreathe.com';
    case 'staging':
      return 'https://staging.justbreathe.com';
    default:
      return 'http://localhost:8081';
  }
})();

export const getGoogleCalendarRedirectUri = (): string => {
  const env = (import.meta.env.VITE_APP_ENV || 'local').toLowerCase() as Environment;
  switch (env) {
    case 'production':
      return 'https://justbreathe.com/google-calendar-callback';
    case 'staging':
      return 'https://staging.justbreathe.com/google-calendar-callback';
    default:
      return 'http://localhost:8081/google-calendar-callback';
  }
}; 