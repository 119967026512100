export interface ThemeColor {
  primary: string;
  secondary: string;
  accent: string;
  background: string;
  text: string;
}

export interface Theme {
  id: string;
  name: string;
  colors: ThemeColor;
}

export const themes: Theme[] = [
  {
    id: 'default',
    name: 'Default',
    colors: {
      primary: '#546a76',
      secondary: '#88a0a8',
      accent: '#b4ceb3',
      background: '#dbd3c9',
      text: '#fad4d8'
    }
  },
  {
    id: 'earthy',
    name: 'Earthy',
    colors: {
      primary: '#aeb4a9',
      secondary: '#e0c1b3',
      accent: '#d89a9e',
      background: '#c37d92',
      text: '#846267'
    }
  },
  {
    id: 'muted',
    name: 'Muted',
    colors: {
      primary: '#734b5e',
      secondary: '#bcbdc0',
      accent: '#565857',
      background: '#8a8d91',
      text: '#f5d3c8'
    }
  },
  {
    id: 'fresh',
    name: 'Fresh',
    colors: {
      primary: '#3ab795',
      secondary: '#a0e8af',
      accent: '#86baa1',
      background: '#edead0',
      text: '#ffcf56'
    }
  },
  {
    id: 'calm',
    name: 'Calm',
    colors: {
      primary: '#96bbbb',
      secondary: '#618985',
      accent: '#414535',
      background: '#f2e3bc',
      text: '#c19875'
    }
  },
  {
    id: 'ocean',
    name: 'Ocean',
    colors: {
      primary: '#c5d5ea',
      secondary: '#759eb8',
      accent: '#7392b7',
      background: '#b3c5d7',
      text: '#d8e1e9'
    }
  },
  {
    id: 'dark',
    name: 'Dark',
    colors: {
      primary: '#1c0f13',
      secondary: '#6e7e85',
      accent: '#b7cece',
      background: '#bbbac6',
      text: '#e2e2e2'
    }
  },
  {
    id: 'nature',
    name: 'Nature',
    colors: {
      primary: '#d0efb1',
      secondary: '#b3d89c',
      accent: '#9dc3c2',
      background: '#77a6b6',
      text: '#4d7298'
    }
  },
  {
    id: 'airy',
    name: 'Airy',
    colors: {
      primary: '#f4faff',
      secondary: '#dee7e7',
      accent: '#b7adcf',
      background: '#4f646f',
      text: '#535657'
    }
  },
  {
    id: 'sunset',
    name: 'Sunset',
    colors: {
      primary: '#2e6171',
      secondary: '#556f7a',
      accent: '#798086',
      background: '#b79fad',
      text: '#d4afcd'
    }
  },
  {
    id: 'pastel',
    name: 'Pastel',
    colors: {
      primary: '#c5e6a6',
      secondary: '#bdd2a6',
      accent: '#b9bea5',
      background: '#a7aaa4',
      text: '#9899a6'
    }
  },
  {
    id: 'lavender',
    name: 'Lavender',
    colors: {
      primary: '#b5b1b2',
      secondary: '#ada9b7',
      accent: '#a9afd1',
      background: '#a1cdf4',
      text: '#7c809b'
    }
  }
];

// Function to get a theme by ID
export function getThemeById(id: string): Theme {
  return themes.find(theme => theme.id === id) || themes[0];
}

// Helper functions for color manipulation
function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

function rgbToHex(r: number, g: number, b: number): string {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function darkenColor(hex: string, percent: number): string {
  const rgb = hexToRgb(hex);
  if (!rgb) return hex;
  
  const factor = 1 - percent / 100;
  const r = Math.round(rgb.r * factor);
  const g = Math.round(rgb.g * factor);
  const b = Math.round(rgb.b * factor);
  
  return rgbToHex(r, g, b);
}

function lightenColor(hex: string, percent: number): string {
  const rgb = hexToRgb(hex);
  if (!rgb) return hex;
  
  const factor = percent / 100;
  const r = Math.round(rgb.r + (255 - rgb.r) * factor);
  const g = Math.round(rgb.g + (255 - rgb.g) * factor);
  const b = Math.round(rgb.b + (255 - rgb.b) * factor);
  
  return rgbToHex(r, g, b);
}

// Function to apply a theme to the document
export function applyTheme(theme: Theme): void {
  console.log('Applying theme:', theme.id, theme.colors);
  
  // Convert hex colors to RGB values
  const primaryRgb = hexToRgb(theme.colors.primary);
  const secondaryRgb = hexToRgb(theme.colors.secondary);
  const accentRgb = hexToRgb(theme.colors.accent);
  const backgroundRgb = hexToRgb(theme.colors.background);
  const textRgb = hexToRgb(theme.colors.text);
  
  if (primaryRgb) {
    document.documentElement.style.setProperty('--color-primary', `${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}`);
  }
  
  if (secondaryRgb) {
    document.documentElement.style.setProperty('--color-secondary', `${secondaryRgb.r}, ${secondaryRgb.g}, ${secondaryRgb.b}`);
  }
  
  if (accentRgb) {
    document.documentElement.style.setProperty('--color-accent', `${accentRgb.r}, ${accentRgb.g}, ${accentRgb.b}`);
  }
  
  if (backgroundRgb) {
    document.documentElement.style.setProperty('--color-background', `${backgroundRgb.r}, ${backgroundRgb.g}, ${backgroundRgb.b}`);
  }
  
  if (textRgb) {
    document.documentElement.style.setProperty('--color-text', `${textRgb.r}, ${textRgb.g}, ${textRgb.b}`);
  }
  
  // Set darker/lighter variants
  const primaryDarkRgb = hexToRgb(darkenColor(theme.colors.primary, 20));
  const primaryLightRgb = hexToRgb(lightenColor(theme.colors.primary, 20));
  const secondaryDarkRgb = hexToRgb(darkenColor(theme.colors.secondary, 20));
  const secondaryLightRgb = hexToRgb(lightenColor(theme.colors.secondary, 20));
  const accentDarkRgb = hexToRgb(darkenColor(theme.colors.accent, 20));
  const accentLightRgb = hexToRgb(lightenColor(theme.colors.accent, 20));
  
  if (primaryDarkRgb) {
    document.documentElement.style.setProperty('--color-primary-dark', `${primaryDarkRgb.r}, ${primaryDarkRgb.g}, ${primaryDarkRgb.b}`);
  }
  
  if (primaryLightRgb) {
    document.documentElement.style.setProperty('--color-primary-light', `${primaryLightRgb.r}, ${primaryLightRgb.g}, ${primaryLightRgb.b}`);
  }
  
  if (secondaryDarkRgb) {
    document.documentElement.style.setProperty('--color-secondary-dark', `${secondaryDarkRgb.r}, ${secondaryDarkRgb.g}, ${secondaryDarkRgb.b}`);
  }
  
  if (secondaryLightRgb) {
    document.documentElement.style.setProperty('--color-secondary-light', `${secondaryLightRgb.r}, ${secondaryLightRgb.g}, ${secondaryLightRgb.b}`);
  }
  
  if (accentDarkRgb) {
    document.documentElement.style.setProperty('--color-accent-dark', `${accentDarkRgb.r}, ${accentDarkRgb.g}, ${accentDarkRgb.b}`);
  }
  
  if (accentLightRgb) {
    document.documentElement.style.setProperty('--color-accent-light', `${accentLightRgb.r}, ${accentLightRgb.g}, ${accentLightRgb.b}`);
  }
  
  // Log the computed styles to verify they're being set
  const computedStyle = getComputedStyle(document.documentElement);
  console.log('Applied CSS variables:');
  console.log('--color-primary:', computedStyle.getPropertyValue('--color-primary'));
  console.log('--color-secondary:', computedStyle.getPropertyValue('--color-secondary'));
  console.log('--color-accent:', computedStyle.getPropertyValue('--color-accent'));
  console.log('--color-background:', computedStyle.getPropertyValue('--color-background'));
  console.log('--color-text:', computedStyle.getPropertyValue('--color-text'));
} 