import { ref } from 'vue';
import { useConfetti } from './useConfetti';
import { isDebugMode } from '@/config';

export function useBadgeNotification() {
  const showBadgeModal = ref(false);
  const currentNotification = ref(null);
  const badgeData = ref({
    name: '',
    description: '',
    imageUrl: '',
    badgeType: 'seedling'
  });
  const { showConfetti } = useConfetti();

  const handleBadgeNotification = (data) => {
    if (isDebugMode()) {
      console.log('[useBadgeNotification] Handling notification:', data);
    }
    
    currentNotification.value = data;
    
    if (data.notification_type === 'badge') {
      const metadata = data.notification_metadata || {};
      
      if (isDebugMode()) {
        console.log('[useBadgeNotification] Badge metadata:', metadata);
      }

      badgeData.value = {
        name: metadata.badge_name || 'New Badge',
        description: metadata.badge_description || data.message || 'You earned a new badge!',
        imageUrl: metadata.image_url || `/badges/${metadata.badge_type || 'seedling'}_badge.svg`,
        badgeType: metadata.badge_type || 'seedling'
      };

      showBadgeModal.value = true;
      showConfetti();
    }
  };

  return {
    showBadgeModal,
    badgeData,
    currentNotification,
    handleBadgeNotification
  };
} 