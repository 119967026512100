<template>
  <div
    id="app"
    class="flex flex-col min-h-screen"
  >
    <NavigationBar
      v-if="!isInitializing"
      :is-logged-in="isLoggedIn"
      :user="user"
      @login-email="loginWithEmail"
    />
    <main class="pt-20">
      <div v-if="!isInitializing" class="flex-grow pb-16">
        <div v-if="!authStore.initialized && authStore.isLoading">
          <RouteTransitionLoader />
        </div>
        <template v-else>
          <router-view v-slot="{ Component }">
            <suspense>
              <template #default>
                <div>
                  <component :is="Component" />
                </div>
              </template>
              <template #fallback>
                <RouteTransitionLoader />
              </template>
            </suspense>
          </router-view>
        </template>
      </div>
      <div v-else>
        <RouteTransitionLoader />
      </div>
    </main>
    <FooterBar
      v-if="!isInitializing"
    />
    <AsyncBadgeAwardModal
      v-if="showBadgeModal"
      v-model="showBadgeModal"
      :badge-name="badgeData.name"
      :description="badgeData.description"
      @close="showBadgeModal = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useRouter } from 'vue-router'
import NavigationBar from '@/components/NavigationBar.vue'
import FooterBar from '@/components/FooterBar.vue'
import { AsyncBadgeAwardModal } from '@/components/async'
import RouteTransitionLoader from '@/components/RouteTransitionLoader.vue'
import { loginWithEmail as loginWithEmailRequest } from '@/utils/auth'
import { useBlockedState } from '@/composables/useBlockedState'
import { isDebugMode } from '@/config'
import { logger } from '@/utils/logger'
import { useBadgeNotification } from '@/composables/useBadgeNotification'
import { useTheme } from '@/composables/useTheme'

// Initialize theme first to ensure it's applied before the app is mounted
useTheme()

const authStore = useAuthStore()
const router = useRouter()
const { showBadgeModal, badgeData } = useBadgeNotification()
const logOptions = { module: 'App' }
const isInitializing = ref(true)

const isLoggedIn = computed(() => authStore.isAuthenticated)
const user = computed(() => authStore.currentUser)

// Set up blocked state router guard
const { setupRouterGuard } = useBlockedState()
setupRouterGuard()

onMounted(async () => {
  logger.debug('App mounted', logOptions)
  try {
    await authStore.initialize()
  } catch (error) {
    logger.error('Failed to initialize auth store', {
      ...logOptions,
      metadata: { error }
    })
    // On initialization error, ensure we're redirected to login
    if (router.currentRoute.value.meta.requiresAuth) {
      router.push('/login')
    }
  } finally {
    isInitializing.value = false
  }
})

const loginWithEmail = async (email: string, password: string) => {
  try {
    const { user, token } = await loginWithEmailRequest(email, password)
    await authStore.login({ user, token })
  } catch (error) {
    if (isDebugMode()) {
      console.error('Login error:', error)
    }
    throw error
  }
}
</script>

<style>
#app {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.loading-placeholder {
  @apply text-gray-600 text-center;
}

.Vue-Toastification__container {
  margin-bottom: 48px !important;
  padding: 0 !important;
}

.Vue-Toastification__toast {
  min-height: 48px !important;
  box-shadow: none !important;
  border-radius: 0 !important; /* Square corners by default (mobile) */
  @apply !bg-primary !text-white;
  margin-bottom: 0 !important;
}

/* Add rounded corners only on desktop */
@media (min-width: 640px) {
  .Vue-Toastification__toast {
    @apply !rounded-lg;
    margin-bottom: 8px !important;
  }
}

.Vue-Toastification__toast--default,
.Vue-Toastification__toast--success,
.Vue-Toastification__toast--error,
.Vue-Toastification__toast--info,
.Vue-Toastification__toast--warning {
  @apply !bg-primary !text-white;
}

.Vue-Toastification__bounce-enter-active {
  animation: Vue-Toastification__bounceIn 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.Vue-Toastification__bounce-leave-active {
  animation: Vue-Toastification__bounceOut 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.Vue-Toastification__bounce-move {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.Vue-Toastification__toast:hover {
  @apply !bg-primary/90;
}

.Vue-Toastification__close-button {
  @apply !text-white opacity-70 hover:opacity-100;
}
</style>