import { AppRouteRecord } from '../types';

const publicRoutes: AppRouteRecord[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import(
      /* webpackChunkName: "public-home" */
      /* webpackPrefetch: true */
      '@/pages/HomePage.vue'
    ),
    meta: { requiresAuth: false }
  },
  {
    path: '/now',
    name: 'Now',
    component: () => import(
      /* webpackChunkName: "public-breathing" */
      /* webpackPrefetch: true */
      '@/pages/BreathingPage.vue'
    ),
    meta: { requiresAuth: false }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(
      /* webpackChunkName: "public-about" */
      /* webpackPrefetch: true */
      '@/pages/AboutPage.vue'
    ),
    meta: { requiresAuth: false }
  },
  {
    path: '/browse',
    name: 'Browse',
    component: () => import(
      /* webpackChunkName: "public-browse" */
      /* webpackPrefetch: true */
      '@/pages/BrowsePage.vue'
    ),
    meta: { requiresAuth: false }
  },
  {
    path: '/releases',
    name: 'Releases',
    component: () => import(
      /* webpackChunkName: "public-releases" */
      '@/pages/ReleasesPage.vue'
    ),
    meta: { requiresAuth: false }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(
      /* webpackChunkName: "public-privacy" */
      '@/pages/PrivacyPolicyPage.vue'
    ),
    meta: { requiresAuth: false }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(
      /* webpackChunkName: "public-terms" */
      '@/pages/TermsPage.vue'
    ),
    meta: { requiresAuth: false }
  },
  {
    path: '/s/:username',
    name: 'PublicProfile',
    component: () => import(
      /* webpackChunkName: "public-profile" */
      /* webpackPrefetch: true */
      '@/pages/PublicProfilePage.vue'
    ),
    meta: { requiresAuth: false }
  },
  {
    path: '/services/:username',
    name: 'PublicProfileLegacy',
    component: () => import(
      /* webpackChunkName: "public-profile" */
      '@/pages/PublicProfilePage.vue'
    ),
    meta: { requiresAuth: false }
  },
  {
    path: '/known-issues',
    name: 'KnownIssues',
    component: () => import(
      /* webpackChunkName: "public-known-issues" */
      '@/pages/KnownIssuesPage.vue'
    ),
    meta: {
      title: 'Known Issues with Alpha',
      requiresAuth: false
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(
      /* webpackChunkName: "public-not-found" */
      '@/pages/NotFoundPage.vue'
    ),
    meta: { requiresAuth: false }
  }
];

export default publicRoutes; 