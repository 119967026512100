import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useFormsStore } from '@/stores/forms';
import { useServicesStore } from '@/stores/services';
import { logger } from '@/utils/logger';
import publicRoutes from './routes/public.routes';
import serviceRoutes from './routes/service.routes';
import settingRoutes from './routes/setting.routes';
import authRoutes from './routes/auth.routes';
import clientRoutes from './routes/client.routes';
import notificationRoutes from './routes/notification.routes';
import bookingRoutes from './routes/booking.routes';
import waitlistRoutes from './routes/waitlist.routes';
import badgeRoutes from './routes/badge.routes';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...publicRoutes,
    ...serviceRoutes,
    ...settingRoutes,
    ...authRoutes,
    ...clientRoutes,
    ...notificationRoutes,
    ...bookingRoutes,
    ...waitlistRoutes,
    ...badgeRoutes
  ]
});

// Data prefetching
const prefetchFormsData = async () => {
  const formsStore = useFormsStore();
  if (!formsStore.forms.length && !formsStore.isLoading) {
    try {
      await formsStore.fetchForms();
    } catch (error) {
      logger.error('Failed to prefetch forms data', {
        module: 'Router',
        error
      });
    }
  }
};

const prefetchServicesData = async () => {
  const servicesStore = useServicesStore();
  if (!servicesStore.services.length && !servicesStore.isLoading) {
    try {
      await servicesStore.fetchServices();
    } catch (error) {
      logger.error('Failed to prefetch services data', {
        module: 'Router',
        error
      });
    }
  }
};

// Add navigation guards
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const logOptions = { module: 'Router' };

  // Initialize auth if needed
  if (!authStore.isInitialized) {
    try {
      await authStore.initialize();
    } catch (error) {
      logger.error('Failed to initialize auth', {
        ...logOptions,
        error
      });
    }
  }

  // Prefetch data based on route
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (authStore.isAuthenticated) {
      // Prefetch forms data for routes that might need it
      if (to.path.includes('/forms') || to.path.includes('/services')) {
        prefetchFormsData();
      }

      // Prefetch services data for service-related routes
      if (to.path.includes('/services') || to.path.includes('/booking')) {
        prefetchServicesData();
      }
    }
  }

  // Handle authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authStore.isAuthenticated) {
      logger.warn('Unauthenticated user attempting to access protected route', {
        ...logOptions,
        metadata: { path: to.path }
      });
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
      return;
    }
  }

  next();
});

// Track page views
router.afterEach((to) => {
  logger.info('Page view', {
    module: 'Router',
    metadata: {
      path: to.path,
      name: to.name
    }
  });
});

export default router;       