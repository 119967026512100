import { useToastService, type ToastType, type ToastConfig } from '@/services/toast';

interface ToastResult {
  showToast: (
    messageOrConfig: string | ToastConfig,
    type?: ToastType,
    options?: Partial<ToastConfig>
  ) => void;
  showSuccessToast: (message: string, options?: Partial<ToastConfig>) => void;
  showErrorToast: (message: string, options?: Partial<ToastConfig>) => void;
  showInfoToast: (message: string, options?: Partial<ToastConfig>) => void;
  showWarningToast: (message: string, options?: Partial<ToastConfig>) => void;
  showRewardToast: (message: string, points: number, options?: Partial<ToastConfig>) => void;
  showBadgeToast: (message: string, badgeInfo: { name: string; imageUrl: string }, options?: Partial<ToastConfig>) => void;
}

export function useToast(): ToastResult {
  const toastService = useToastService();

  // Create a wrapper function that handles both formats
  const showToast = (
    messageOrConfig: string | ToastConfig,
    type: ToastType = 'info',
    options: Partial<ToastConfig> = {}
  ) => {
    // Default accessibility and styling options
    const defaultOptions: Partial<ToastConfig> = {
      timeout: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      hideProgressBar: false
    };

    // If first argument is an object, treat it as config
    if (typeof messageOrConfig === 'object' && messageOrConfig !== null) {
      return toastService.showToast({
        ...defaultOptions,
        ...messageOrConfig
      });
    }
    
    // Otherwise, treat it as message with optional type and options
    return toastService.showToast({
      ...defaultOptions,
      message: messageOrConfig,
      type,
      ...options
    });
  };

  const showSuccessToast = (message: string, options: Partial<ToastConfig> = {}) => {
    return showToast(message, 'success', options);
  };

  const showErrorToast = (message: string, options: Partial<ToastConfig> = {}) => {
    return showToast(message, 'error', options);
  };

  const showInfoToast = (message: string, options: Partial<ToastConfig> = {}) => {
    return showToast(message, 'info', options);
  };

  const showWarningToast = (message: string, options: Partial<ToastConfig> = {}) => {
    return showToast(message, 'warning', options);
  };

  const showRewardToast = (message: string, points: number, options: Partial<ToastConfig> = {}) => {
    return showToast({
      message,
      type: 'reward',
      rewardPoints: points,
      ...options
    });
  };

  const showBadgeToast = (message: string, badgeInfo: { name: string; imageUrl: string }, options: Partial<ToastConfig> = {}) => {
    return showToast({
      message,
      type: 'badge',
      badgeInfo,
      ...options
    });
  };

  return {
    showToast,
    showSuccessToast,
    showErrorToast,
    showInfoToast,
    showWarningToast,
    showRewardToast,
    showBadgeToast
  };
} 