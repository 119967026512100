import { useToast, POSITION, type PluginOptions } from 'vue-toastification';
import { icons } from '@/assets/icons';
import { isDebugMode } from '@/config';

export type ToastType = 'success' | 'error' | 'info' | 'warning' | 'reward' | 'badge' | 'custom' | 'delete-success';

export interface BadgeInfo {
  name: string;
  imageUrl: string;
}

// Extend the PluginOptions type to include our custom handlers
export interface ExtendedPluginOptions extends PluginOptions {
  onClose?: () => void;
  onOpen?: () => void;
}

export interface ToastConfig extends Partial<Omit<ExtendedPluginOptions, 'position'>> {
  message?: string;
  type?: ToastType;
  rewardPoints?: number;
  badgeInfo?: BadgeInfo;
  icon?: string;
  customClass?: string;
}

// Base options that should never be overridden
const baseOptions = {
  position: POSITION.BOTTOM_RIGHT
} as const;

const defaultOptions: Partial<ExtendedPluginOptions> = {
  ...baseOptions,
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  closeButton: 'button',
  icon: true,
  rtl: false,
  transition: 'Vue-Toastification__bounce',
  hideProgressBar: false
};

// Custom toast classes for different types
const toastTypeClasses = {
  success: '!bg-primary hover:!bg-primary/90',
  error: '!bg-primary hover:!bg-primary/90',
  info: '!bg-primary hover:!bg-primary/90',
  warning: '!bg-primary hover:!bg-primary/90',
  reward: '!bg-primary hover:!bg-primary/90',
  badge: '!bg-primary hover:!bg-primary/90 min-w-[400px] p-6 sm:rounded-xl',
  'delete-success': '!bg-primary hover:!bg-primary/90',
  custom: '!bg-primary hover:!bg-primary/90'
};

const getToastClass = (type: ToastType): string => {
  const baseClasses = [
    'sm:rounded-lg',
    'flex',
    'items-center',
    'justify-between',
    'min-h-[48px]',
    'px-4',
    'py-3',
    'transition-all',
    'duration-300',
    'ease-in-out',
    'cursor-pointer',
    'text-white'
  ].join(' ');

  return `${baseClasses} ${toastTypeClasses[type]}`;
};

export const useToastService = () => {
  const toast = useToast();

  const showToast = ({
    message = 'An event occurred',
    type = 'info',
    rewardPoints,
    badgeInfo,
    icon = type in icons ? icons[type as keyof typeof icons] : undefined,
    customClass = '',
    ...options
  }: ToastConfig) => {
    if (isDebugMode()) {
      console.log('Showing toast:', { message, type, rewardPoints, badgeInfo, icon, customClass, options });
    }

    // Create the toast content
    let content = message;
    
    // Add reward points if present
    if (rewardPoints) {
      content = `${message} (+${rewardPoints} points)`;
    }

    // Add badge info if present
    if (badgeInfo) {
      content = `${message}\n${badgeInfo.name}`;
    }

    try {
      return toast(content, {
        ...defaultOptions,
        ...options,
        ...baseOptions, // Ensure position is always bottom-right
        toastClassName: `${getToastClass(type)} ${customClass}`,
        type: type as any
      });
    } catch (error) {
      if (isDebugMode()) {
        console.error('Error showing toast:', error);
      }
      // Fallback to a simpler toast if something goes wrong
      return toast(message, {
        ...defaultOptions,
        ...baseOptions, // Ensure position is always bottom-right
        type: type as any,
        timeout: 3000
      });
    }
  };

  const showSuccessToast = (message: string, options?: Partial<ToastConfig>) => {
    showToast({ message, type: 'success', ...options });
  };

  const showErrorToast = (message: string, options?: Partial<ToastConfig>) => {
    showToast({ message, type: 'error', ...options });
  };

  const showInfoToast = (message: string, options?: Partial<ToastConfig>) => {
    showToast({ message, type: 'info', ...options });
  };

  const showWarningToast = (message: string, options?: Partial<ToastConfig>) => {
    showToast({ message, type: 'warning', ...options });
  };

  const showRewardToast = (message: string, points: number, options?: Partial<ToastConfig>) => {
    showToast({
      message,
      type: 'reward',
      rewardPoints: points,
      ...options
    });
  };

  const showBadgeToast = (message: string, badgeInfo: BadgeInfo, options?: Partial<ToastConfig>) => {
    showToast({
      message,
      type: 'badge',
      badgeInfo,
      ...options
    });
  };

  return {
    showToast,
    showSuccessToast,
    showErrorToast,
    showInfoToast,
    showWarningToast,
    showRewardToast,
    showBadgeToast
  };
}; 