import mitt from 'mitt';
import { logger } from '@/utils/logger';

const logOptions = { module: 'EventBus' };

logger.debug('Initializing event bus', logOptions);

const emitter = mitt();

export const eventBus = {
  emit: (event, data) => {
    logger.debug('Emitting event', {
      ...logOptions,
      metadata: { event, data }
    });
    emitter.emit(event, data);
  },
  on: (event, callback) => {
    logger.debug('Subscribing to event', {
      ...logOptions,
      metadata: { event }
    });
    emitter.on(event, callback);
  },
  off: (event, callback) => {
    logger.debug('Unsubscribing from event', {
      ...logOptions,
      metadata: { event }
    });
    emitter.off(event, callback);
  }
};

export const MENU_EVENT = 'menu-state-change';