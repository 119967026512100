import mixpanel from 'mixpanel-browser';
import { logger } from '@/utils/logger';
import { getMixpanelConfig } from '@/config/mixpanel';

const logOptions = { module: 'Analytics' };

interface AnalyticsConfig {
  requireCookieConsent?: boolean;
}

interface UserTraits {
  [key: string]: string | number | boolean | null;
}

interface EventProperties {
  [key: string]: string | number | boolean | null;
}

// Get environment from Vite env
const ENVIRONMENT = (import.meta.env.VITE_APP_ENV || 'local').toLowerCase();

// Check if we're in a test environment
const isTestEnvironment = (): boolean => {
  return process.env.NODE_ENV === 'test' || import.meta.env.VITEST !== undefined;
};

// Format user ID consistently with backend
const getPrefixedUserId = (userId: string): string => `${ENVIRONMENT}_${userId}`;

export const initializeAnalytics = ({ requireCookieConsent = true }: AnalyticsConfig = {}): void => {
  try {
    // Skip Mixpanel initialization in test environment
    if (isTestEnvironment()) {
      logger.info('Analytics initialization skipped in test environment', logOptions);
      return;
    }

    const config = getMixpanelConfig();
    
    mixpanel.init(config.token, {
      debug: config.debug,
      ignore_dnt: !requireCookieConsent
    });

    logger.info('Analytics initialized', {
      ...logOptions,
      metadata: { requireCookieConsent, debug: config.debug, environment: ENVIRONMENT }
    });
  } catch (error) {
    logger.error('Failed to initialize analytics', {
      ...logOptions,
      metadata: { error: error instanceof Error ? error.message : String(error) }
    });
  }
};

export const identify = async (userId: string, traits: UserTraits = {}): Promise<void> => {
  try {
    // Skip identification in test environment
    if (isTestEnvironment()) {
      logger.debug('User identification skipped in test environment', {
        ...logOptions,
        metadata: { userId, traits }
      });
      return;
    }

    const prefixedId = getPrefixedUserId(userId);
    await new Promise<void>((resolve) => {
      // First reset the identity to ensure clean state
      mixpanel.reset();
      
      // Then identify with the prefixed user ID
      mixpanel.identify(prefixedId);
      
      // Set user properties
      mixpanel.people.set({
        ...traits,
        environment: ENVIRONMENT,
        $name: traits.name || traits.email || prefixedId,
        userId: userId // Store original user ID as a property
      });
      
      resolve();
    });
    
    logger.debug('User identified', {
      ...logOptions,
      metadata: { userId: prefixedId, traits, environment: ENVIRONMENT }
    });
  } catch (error) {
    logger.error('Failed to identify user', {
      ...logOptions,
      metadata: { 
        userId,
        traits,
        environment: ENVIRONMENT,
        error: error instanceof Error ? error.message : String(error)
      }
    });
    throw error;
  }
};

export const track = async (event: string, properties: EventProperties = {}): Promise<void> => {
  try {
    // Skip tracking in test environment
    if (isTestEnvironment()) {
      logger.debug('Event tracking skipped in test environment', {
        ...logOptions,
        metadata: { event, properties }
      });
      return;
    }

    await new Promise<void>((resolve) => {
      mixpanel.track(event, {
        ...properties,
        environment: ENVIRONMENT
      });
      resolve();
    });
    
    logger.debug('Event tracked', {
      ...logOptions,
      metadata: { 
        event, 
        properties, 
        environment: ENVIRONMENT,
        distinctId: mixpanel.get_distinct_id()
      }
    });
  } catch (error) {
    logger.error('Failed to track event', {
      ...logOptions,
      metadata: { 
        event,
        properties,
        environment: ENVIRONMENT,
        error: error instanceof Error ? error.message : String(error)
      }
    });
    throw error;
  }
};

export const trackPageView = async (pageName: string, properties: EventProperties = {}): Promise<void> => {
  try {
    // Skip page view tracking in test environment
    if (isTestEnvironment()) {
      logger.debug('Page view tracking skipped in test environment', {
        ...logOptions,
        metadata: { pageName, properties }
      });
      return;
    }

    await track(`Page View - ${pageName}`, {
      page: pageName,
      ...properties,
      environment: ENVIRONMENT
    });
    
    logger.debug('Page view tracked', {
      ...logOptions,
      metadata: { 
        pageName, 
        properties, 
        environment: ENVIRONMENT,
        distinctId: mixpanel.get_distinct_id()
      }
    });
  } catch (error) {
    logger.error('Failed to track page view', {
      ...logOptions,
      metadata: { 
        pageName,
        properties,
        environment: ENVIRONMENT,
        error: error instanceof Error ? error.message : String(error)
      }
    });
    // Don't throw error for page views to prevent breaking navigation
  }
}; 