import { ref, onMounted, Ref } from 'vue'
import { useRouter } from 'vue-router'
import Cookies from 'js-cookie'

interface BlockedState {
  isBlocked: Ref<boolean>
  setBlockedState: (blockedUntil: Date, returnPath: string) => void
  clearBlockedState: () => void
  checkBlockedState: () => boolean
  setupRouterGuard: () => void
}

const BLOCKED_COOKIE_NAME = 'passcode_blocked_until'
const BLOCKED_RETURN_PATH = 'passcode_return_path'

export function useBlockedState(): BlockedState {
  const router = useRouter()
  const isBlocked = ref(false)

  const setBlockedState = (blockedUntil: Date, returnPath: string): void => {
    // Store blocked until timestamp and return path in cookies
    Cookies.set(BLOCKED_COOKIE_NAME, blockedUntil.toISOString(), {
      expires: blockedUntil,
      sameSite: 'strict',
      secure: true // Ensure cookie is only sent over HTTPS
    })
    Cookies.set(BLOCKED_RETURN_PATH, returnPath, {
      expires: blockedUntil,
      sameSite: 'strict',
      secure: true
    })
    isBlocked.value = true
  }

  const clearBlockedState = (): void => {
    Cookies.remove(BLOCKED_COOKIE_NAME)
    Cookies.remove(BLOCKED_RETURN_PATH)
    isBlocked.value = false
  }

  const checkBlockedState = (): boolean => {
    const blockedUntil = Cookies.get(BLOCKED_COOKIE_NAME)
    
    if (blockedUntil) {
      const blockedDate = new Date(blockedUntil)
      
      // If still blocked
      if (blockedDate > new Date()) {
        isBlocked.value = true
        return true
      } else {
        // If block period has expired, clear the cookies
        clearBlockedState()
      }
    }
    
    return false
  }

  const setupRouterGuard = (): void => {
    router.beforeEach((to, from, next) => {
      const blockedUntil = Cookies.get(BLOCKED_COOKIE_NAME)
      
      if (blockedUntil) {
        const blockedDate = new Date(blockedUntil)
        
        // If still blocked
        if (blockedDate > new Date()) {
          // Allow navigation only to the blocked page
          if (to.path === '/blocked') {
            next()
          } else {
            // Redirect to blocked page with current path as return destination
            const returnPath = Cookies.get(BLOCKED_RETURN_PATH) || '/'
            next({
              path: '/blocked',
              query: {
                until: blockedDate.toISOString(),
                returnTo: returnPath
              }
            })
          }
        } else {
          // If block period has expired, clear the cookies and allow navigation
          clearBlockedState()
          next()
        }
      } else {
        // Not blocked, allow navigation
        next()
      }
    })
  }

  onMounted(() => {
    checkBlockedState()
  })

  return {
    isBlocked,
    setBlockedState,
    clearBlockedState,
    checkBlockedState,
    setupRouterGuard
  }
} 