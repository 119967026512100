import { AppRouteRecord } from '../types';

const bookingRoutes: AppRouteRecord[] = [
  {
    path: '/book/:serviceId/:optionId',
    name: 'BookService',
    component: () => import('@/pages/BookServicePage.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/book/:serviceId/create',
    name: 'CreateBooking',
    component: () => import('@/pages/CreateBookingPage.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/booking/confirmation/:confirmationToken',
    name: 'BookingConfirmation',
    component: () => import('@/pages/BookingConfirmationPage.vue'),
    meta: { requiresAuth: false }
  }
];

export default bookingRoutes; 