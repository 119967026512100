import { Environment } from '@/config';
import { logger } from '@/utils/logger';

interface MixpanelConfig {
  token: string;
  debug: boolean;
}

const CONFIGS: Record<Environment, MixpanelConfig> = {
  production: {
    token: "bddb2355588604af185a0c5a866bd95a",
    debug: false
  },
  staging: {
    token: "bddb2355588604af185a0c5a866bd95a",  // Same token for staging
    debug: true
  },
  local: {
    token: "bddb2355588604af185a0c5a866bd95a",  // Same token for local
    debug: true
  }
};

const logOptions = { module: 'MixpanelConfig' };

export const getMixpanelConfig = (): MixpanelConfig => {
  const env = (import.meta.env.VITE_APP_ENV || 'local').toLowerCase() as Environment;
  
  logger.debug('Getting Mixpanel config', {
    ...logOptions,
    metadata: {
      env,
      rawEnv: import.meta.env.VITE_APP_ENV,
      debug: CONFIGS[env].debug
    }
  });

  const config = CONFIGS[env] || CONFIGS.local;
  
  if (!config.token) {
    logger.error('No Mixpanel token found', {
      ...logOptions,
      metadata: { env, availableEnvs: Object.keys(CONFIGS) }
    });
    throw new Error(`Mixpanel token not configured for environment: ${env}`);
  }

  return config;
}; 