type LogLevel = 'error' | 'warn' | 'info' | 'debug';
type Environment = 'production' | 'staging' | 'local';

export interface LogOptions {
  module?: string;
  metadata?: Record<string, any>;
}

export class Logger {
  private static instance: Logger;
  private debugMode: boolean = false;
  private environment: Environment = 'production';
  private initialized: boolean = false;

  private constructor() {
    this.initializeLogger();
  }

  private initializeLogger() {
    try {
      const env = (import.meta.env.VITE_APP_ENV || '').toLowerCase() as Environment;
      this.environment = env;
      
      // Always enable debug mode in staging, unless explicitly disabled
      if (env === 'staging' && import.meta.env.VITE_DEBUG !== 'false') {
        this.debugMode = true;
      } else {
        // For other environments, check VITE_DEBUG
        this.debugMode = import.meta.env.VITE_DEBUG === 'true';
      }
      this.initialized = true;
    } catch (error) {
      console.warn('Logger initialization deferred - environment not ready');
      this.initialized = false;
    }
  }

  private ensureInitialized() {
    if (!this.initialized) {
      this.initializeLogger();
    }
  }

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  private formatMessage(level: LogLevel, message: string, options?: LogOptions): string {
    const timestamp = new Date().toISOString();
    const module = options?.module ? `[${options.module}]` : '';
    return `${timestamp} ${level.toUpperCase()} ${module} ${message}`;
  }

  private shouldLog(level: LogLevel): boolean {
    this.ensureInitialized();
    
    // Always log errors regardless of environment or debug mode
    if (level === 'error') return true;

    switch (this.environment) {
      case 'production':
        // Only log errors and warnings in production
        //return level === 'warn';
        return true;
      case 'staging':
        // Log everything in staging
        return true;
      case 'local':
      default:
        // In local environment, only show debug/info if debug mode is enabled
        if (level === 'warn') return true;
        return this.debugMode;
    }
  }

  private log(level: LogLevel, message: string | Error, options?: LogOptions): void {
    this.ensureInitialized();
    
    if (!this.shouldLog(level)) return;
    
    const formattedMessage = this.formatMessage(level, message instanceof Error ? message.message : message, options);
    const metadata = options?.metadata;

    switch (level) {
      case 'error':
        console.error(formattedMessage, metadata || '');
        if (message instanceof Error && message.stack) {
          console.error(message.stack);
        }
        break;
      case 'warn':
        console.warn(formattedMessage, metadata || '');
        break;
      case 'info':
      case 'debug':
        console.log(formattedMessage, metadata || '');
        break;
    }
  }

  public error(message: string | Error, options?: LogOptions): void {
    this.log('error', message, options);
  }

  public warn(message: string, options?: LogOptions): void {
    this.log('warn', message, options);
  }

  public info(message: string, options?: LogOptions): void {
    this.log('info', message, options);
  }

  public debug(message: string, options?: LogOptions): void {
    this.log('debug', message, options);
  }

  // Helper method to check if debug logging is enabled
  public isDebugEnabled(): boolean {
    return this.debugMode;
  }

  // Conditional debug logging helper
  public debugIf(condition: boolean, message: string, options?: LogOptions): void {
    if (condition) {
      this.debug(message, options);
    }
  }

  // Helper method to get current environment
  public getEnvironment(): Environment {
    return this.environment;
  }
}

export const logger = Logger.getInstance();

// Export a type-safe way to check debug mode
export const isDebugLoggingEnabled = (): boolean => logger.isDebugEnabled(); 