// Import statements first
import { createApp, type App as VueApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './stores'
import { useAuthStore } from './stores'
import './assets/tailwind.css'
// Import the theme CSS to ensure it's included in the build
import './assets/styles/themes.css'
// Dynamic refresh is handled in the useTheme composable
import { initializeAnalytics } from './utils/analytics'
import { isDebugMode } from './config'
import Toast, { POSITION, type PluginOptions } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { logger } from './utils/logger';

interface RequiredEnvVars {
  VITE_APP_ENV: string | undefined
  VITE_DEBUG_MODE: string | undefined
  VITE_API_URL: string | undefined
}

// Function to check environment
const checkEnvironment = () => {
  logger.debug('Environment check:', {
    module: 'App',
    metadata: {
      VITE_APP_ENV: import.meta.env.VITE_APP_ENV,
      VITE_DEBUG_MODE: import.meta.env.VITE_DEBUG_MODE,
      MODE: import.meta.env.MODE
    }
  });
};

// Create Vue app instance first
const app: VueApp = createApp(App)

// Initialize Pinia and router
app.use(pinia)
app.use(router)

// Configure error handling
app.config.errorHandler = (error: unknown, vm: any, info: string) => {
  logger.error('Global error occurred', {
    module: 'App',
    metadata: {
      error,
      component: vm?.$options?.name || 'Unknown',
      info
    }
  });
}

// Check environment variables
if (import.meta.env.DEV || import.meta.env.VITE_APP_ENV === 'staging') {
  checkEnvironment();
  const requiredVars: RequiredEnvVars = {
    VITE_APP_ENV: import.meta.env.VITE_APP_ENV,
    VITE_DEBUG_MODE: import.meta.env.VITE_DEBUG_MODE,
    VITE_API_URL: import.meta.env.VITE_API_URL
  };

  logger.debug('Environment Variables', {
    module: 'App',
    metadata: requiredVars
  });
  
  const missingVars = Object.entries(requiredVars)
    .filter(([, value]) => !value)
    .map(([key]) => key);

  if (missingVars.length > 0) {
    logger.error('Missing required environment variables', {
      module: 'App',
      metadata: { missingVars }
    });
  }
}

// Initialize analytics after environment check
initializeAnalytics({
  requireCookieConsent: true
})

logger.debug('Environment Debug', {
  module: 'App',
  metadata: {
    VITE_APP_ENV: import.meta.env.VITE_APP_ENV?.toLowerCase(),
    hostname: window.location.hostname,
    mode: import.meta.env.MODE,
    isDebug: isDebugMode()
  }
});

// Configure toast
app.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
  transition: 'Vue-Toastification__bounce',
  maxToasts: 5,
  newestOnTop: true,
  filterBeforeCreate: (toast: unknown, toasts: unknown[]) => {
    if (toasts.length >= 5) {
      return false;
    }
    return toast;
  }
} as PluginOptions);

// Create a function to initialize the app
const initializeApp = async () => {
  try {
    logger.debug('Starting app initialization', { module: 'App' });
    
    // Initialize auth store first
    const authStore = useAuthStore();
    const initPromise = authStore.initialize();
    
    // Mount the app immediately to show loading state
    app.mount('#app');
    
    // Wait for auth initialization to complete
    await initPromise;
    
    logger.debug('Auth initialized', { 
      module: 'App',
      metadata: {
        isAuthenticated: authStore.isAuthenticated,
        isInitialized: authStore.initialized
      }
    });
  } catch (error) {
    logger.error('App initialization error', {
      module: 'App',
      metadata: { error }
    });
  }
}

// Start initialization
initializeApp();    