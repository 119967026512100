<template>
  <div>
    <Suspense>
      <template #default>
        <slot />
      </template>
      <template #fallback>
        <div 
          class="animate-pulse relative max-w-4xl mx-auto p-4"
          role="status"
          :aria-label="loadingLabel"
        >
          <div class="h-8 bg-secondary-light rounded w-3/4 mx-auto mb-4" />
          <div class="bg-white shadow-md rounded-lg p-4">
            <div class="h-6 bg-primary-light rounded w-1/2 mx-auto mb-4" />
            <div class="flex flex-col items-center space-y-4">
              <div class="h-10 bg-secondary-light rounded w-64" />
              <div class="h-10 bg-secondary-light rounded w-64" />
            </div>
          </div>
        </div>
      </template>
    </Suspense>
    <div 
      v-if="error"
      class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4"
      role="alert"
      aria-live="polite"
    >
      <strong class="font-bold">Error!</strong>
      <span class="block sm:inline">{{ error.message }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onErrorCaptured } from 'vue';
import { logger } from '@/utils/logger';

const props = defineProps<{
  loadingLabel?: string;
}>();

const error = ref<Error | null>(null);

onErrorCaptured((err: Error) => {
  error.value = err;
  logger.error('Component error captured', {
    module: 'AsyncComponentWrapper',
    metadata: { error: err.message }
  });
  return false; // Prevent error from propagating
});
</script>

<style scoped>
.animate-pulse {
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style> 