import api from '@/utils/api';
import type { User } from '@/types/user';
import { logger } from '@/utils/logger';

const logOptions = { module: 'Auth' };

export interface LoginResponse {
  user: User;
  token: string;
}

/**
 * Login with email and password
 */
export async function loginWithEmail(email: string, password: string): Promise<LoginResponse> {
  try {
    logger.debug('Attempting login with email', {
      ...logOptions,
      metadata: { email }
    });
    const response = await api.post('/auth/login', { email, password });
    logger.debug('Login successful', {
      ...logOptions,
      metadata: { email }
    });
    return response.data;
  } catch (error) {
    logger.error('Login failed', {
      ...logOptions,
      metadata: {
        email,
        error: error instanceof Error ? error.message : String(error)
      }
    });
    throw error;
  }
}

export function generateRandomString(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  logger.debug('Generated random string', {
    ...logOptions,
    metadata: { length }
  });
  return result;
}