import { AppRouteRecord } from '../types';

const serviceRoutes: AppRouteRecord[] = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(
      /* webpackChunkName: "service-dashboard" */
      /* webpackPrefetch: true */
      '@/pages/HomePage.vue'
    ),
    meta: { requiresAuth: true }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import(
      /* webpackChunkName: "service-list" */
      /* webpackPrefetch: true */
      '@/pages/ServicesPage.vue'
    ),
    meta: { requiresAuth: true }
  },
  {
    path: '/email-templates',
    name: 'EmailTemplates',
    component: () => import(
      /* webpackChunkName: "service-email-templates" */
      '@/pages/EmailTemplatesPage.vue'
    ),
    meta: { requiresAuth: true }
  },
  {
    path: '/forms',
    name: 'Forms',
    component: () => import(
      /* webpackChunkName: "service-forms" */
      '@/pages/FormsPage.vue'
    ),
    meta: { requiresAuth: true }
  },
  {
    path: '/services/:id',
    name: 'ServiceDetails',
    component: () => import(
      /* webpackChunkName: "service-details" */
      /* webpackPrefetch: true */
      '@/pages/ServiceDetailsPage.vue'
    ),
    meta: { requiresAuth: true }
  },
  {
    path: '/services/new',
    name: 'NewService',
    component: () => import(
      /* webpackChunkName: "service-new" */
      '@/pages/NewServicePage.vue'
    ),
    meta: { requiresAuth: true }
  },
  {
    path: '/notification-test',
    name: 'NotificationTest',
    component: () => import(
      /* webpackChunkName: "service-notification-test" */
      '@/pages/NotificationTestPage.vue'
    ),
    meta: { requiresAuth: true }
  }
];

export default serviceRoutes; 