import { onMounted, watch } from 'vue';
import { useSettingsStore } from '@/stores/settings';
import { getThemeById, applyTheme, themes } from '@/config/themes';
import { logger } from '@/utils/logger';

// Force refresh of theme CSS
const forceThemeCssRefresh = () => {
  try {
    // Remove any existing theme stylesheet with our marker
    const existingLink = document.head.querySelector('link[data-theme-stylesheet="true"]');
    if (existingLink) {
      document.head.removeChild(existingLink);
    }
    
    // Create a new link element with a timestamp to prevent caching
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    // Use a path that works in both development and production
    link.href = `${import.meta.env.BASE_URL || '/'}assets/styles/themes.css?v=${Date.now()}`;
    link.dataset.themeStylesheet = 'true';
    document.head.appendChild(link);
    
    logger.debug('Theme CSS refreshed', {
      module: 'useTheme',
      metadata: { timestamp: Date.now() }
    });
  } catch (error) {
    logger.error('Error refreshing theme CSS', {
      module: 'useTheme',
      metadata: { error }
    });
  }
};

// Apply theme immediately from localStorage if available
const applyInitialTheme = () => {
  try {
    // Force refresh the theme CSS first
    forceThemeCssRefresh();
    
    // Try to get theme from localStorage first
    const savedTheme = localStorage.getItem('user_theme');
    if (savedTheme) {
      const theme = getThemeById(savedTheme);
      applyTheme(theme);
      logger.debug('Theme applied from localStorage', {
        module: 'useTheme',
        metadata: {
          themeId: theme.id,
          themeName: theme.name
        }
      });
      return;
    }
    
    // Fallback to default theme
    const defaultTheme = themes[0];
    applyTheme(defaultTheme);
    logger.debug('Default theme applied', {
      module: 'useTheme',
      metadata: {
        themeId: defaultTheme.id,
        themeName: defaultTheme.name
      }
    });
  } catch (error) {
    logger.error('Error applying initial theme', {
      module: 'useTheme',
      metadata: { error }
    });
    
    // Apply default theme as fallback
    try {
      const defaultTheme = themes[0];
      applyTheme(defaultTheme);
    } catch (e) {
      logger.error('Failed to apply default theme', {
        module: 'useTheme',
        metadata: { error: e }
      });
    }
  }
};

// Apply theme immediately
applyInitialTheme();

export function useTheme() {
  const settingsStore = useSettingsStore();

  // Apply the theme when the component mounts
  onMounted(() => {
    try {
      // Force refresh the theme CSS
      forceThemeCssRefresh();
      
      const theme = getThemeById(settingsStore.state.theme);
      applyTheme(theme);
      
      // Save theme to localStorage for faster loading on refresh
      localStorage.setItem('user_theme', theme.id);
      
      logger.debug('Theme applied on mount', {
        module: 'useTheme',
        metadata: {
          themeId: theme.id,
          themeName: theme.name
        }
      });
    } catch (error) {
      logger.error('Error applying theme on mount', {
        module: 'useTheme',
        metadata: { error }
      });
    }
  });

  // Watch for theme changes and apply them
  watch(() => settingsStore.state.theme, (newThemeId) => {
    try {
      // Force refresh the theme CSS
      forceThemeCssRefresh();
      
      const theme = getThemeById(newThemeId);
      applyTheme(theme);
      
      // Save theme to localStorage for faster loading on refresh
      localStorage.setItem('user_theme', theme.id);
      
      logger.debug('Theme changed and applied', {
        module: 'useTheme',
        metadata: {
          themeId: theme.id,
          themeName: theme.name
        }
      });
    } catch (error) {
      logger.error('Error applying theme on change', {
        module: 'useTheme',
        metadata: { error }
      });
    }
  });

  return {
    currentTheme: () => getThemeById(settingsStore.state.theme)
  };
} 