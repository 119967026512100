import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import api from '@/utils/api'
import { setToken, removeToken } from '@/utils/tokenStorage'
import { generateRandomString } from '@/utils/auth'
import type { User } from '@/types/user'
import type { GoogleLoginPayload, GoogleLoginResult } from '@/types/auth'
import { logger } from '@/utils/logger'
import { identify } from '@/utils/analytics'

interface EmailLoginPayload {
  email: string;
  password: string;
}

export const useAuthStore = defineStore('auth', () => {
  // State
  const isAuthenticated = ref(false)
  const token = ref<string | null>(null)
  const loading = ref(false)
  const initialized = ref(false)
  const error = ref<string | null>(null)
  const apiVersion = ref<string | null>(null)
  const currentUser = ref<User | null>(null)
  const isCleanLogout = ref(false)
  const initializationPromise = ref<Promise<void> | null>(null)
  const isRefreshingToken = ref(false)
  const pendingGoogleAuth = ref<{ credential: string; state: string; email: string } | null>(null)

  // Getters
  const isLoading = computed(() => loading.value)
  const isInitialized = computed(() => initialized.value)
  const getError = computed(() => error.value)
  const getToken = computed(() => token.value)
  const getCurrentUser = computed(() => currentUser.value)
  const getIsCleanLogout = computed(() => isCleanLogout.value)

  // Actions
  const setAuthToken = (newToken: string | null) => {
    logger.debug('Setting token', {
      module: 'Auth',
      metadata: { hasToken: !!newToken }
    });
    token.value = newToken
    if (newToken) {
      setToken(newToken)
    } else {
      removeToken()
    }
  }

  const setAuthenticated = (value: boolean) => {
    isAuthenticated.value = value
  }

  const setCurrentUser = (user: User | null) => {
    currentUser.value = user
  }

  const setInitialized = (value: boolean) => {
    initialized.value = value
  }

  const refreshToken = async (): Promise<string | null> => {
    // Don't attempt refresh if we're not authenticated
    if (!isAuthenticated.value || !token.value) {
      logger.debug('Skipping token refresh - not authenticated', {
        module: 'Auth',
        metadata: { isAuthenticated: isAuthenticated.value }
      });
      return null;
    }

    if (isRefreshingToken.value) {
      logger.debug('Token refresh already in progress', { module: 'Auth' });
      // Wait for the existing refresh to complete
      let retryCount = 0;
      while (isRefreshingToken.value && retryCount < 50) {
        await new Promise(resolve => setTimeout(resolve, 100));
        retryCount++;
      }
      if (isRefreshingToken.value) {
        logger.warn('Token refresh timeout - clearing auth state', { module: 'Auth' });
        await cleanLogout();
        throw new Error('Token refresh timeout');
      }
      return token.value;
    }

    try {
      isRefreshingToken.value = true;
      logger.debug('Starting token refresh', { module: 'Auth' });
      
      const response = await api.post('/auth/refresh', {}, {
        headers: {
          'Authorization': `Bearer ${token.value}`
        }
      });

      if (!response.data?.access_token) {
        throw new Error('No access token received');
      }

      const newToken = response.data.access_token;
      setAuthToken(newToken);
      
      // After successful token refresh, try to restore the session
      try {
        await restoreSession(newToken);
      } catch (sessionErr) {
        logger.error('Failed to restore session after token refresh', {
          module: 'Auth',
          metadata: { error: sessionErr }
        });
        throw sessionErr;
      }
      
      return newToken;
    } catch (err) {
      logger.error('Token refresh failed', {
        module: 'Auth',
        metadata: { error: err }
      });
      
      // Only clear auth state if it's not a network error
      if (!(err instanceof Error && err.message.includes('Network Error'))) {
        await cleanLogout();
      }

      throw err;
    } finally {
      isRefreshingToken.value = false;
    }
  };

  const initialize = async () => {
    logger.debug('Starting initialization...', { module: 'Auth' });
    
    // If already initialized, return
    if (initialized.value) {
      return;
    }

    // If initialization is in progress, wait for it
    if (initializationPromise.value) {
      return initializationPromise.value;
    }

    // Create new initialization promise
    initializationPromise.value = (async () => {
      try {
        initialized.value = false;
        loading.value = true;
        const savedToken = localStorage.getItem('token');
        
        if (!savedToken) {
          logger.debug('No token found, completing initialization', { module: 'Auth' });
          return;
        }

        // Set the token first
        setAuthToken(savedToken);
        setAuthenticated(true); // Set authenticated state before refresh
        
        // Try to restore the session
        try {
          // First try to refresh the token
          const newToken = await refreshToken();
          if (!newToken) {
            throw new Error('Token refresh failed');
          }
        } catch (err) {
          logger.error('Session restoration failed', {
            module: 'Auth',
            metadata: { error: err }
          });
          // Only clear auth state if it's not a network error
          if (!(err instanceof Error && err.message.includes('Network Error'))) {
            await cleanLogout();
          }
          // Don't throw here, just complete initialization
          return;
        }
      } catch (err) {
        logger.error('Initialization failed', {
          module: 'Auth',
          metadata: { error: err }
        });
        // Only clear auth state if it's not a network error
        if (!(err instanceof Error && err.message.includes('Network Error'))) {
          await cleanLogout();
        }
        // Don't throw here, just complete initialization
        return;
      } finally {
        loading.value = false;
        initializationPromise.value = null;
      }
    })();

    await initializationPromise.value;
    initialized.value = true;
    return initializationPromise.value;
  }

  const login = async (payload: { user: User; token: string }) => {
    try {
      setAuthToken(payload.token)
      setCurrentUser(payload.user)
      setAuthenticated(true)

      // Identify user in Mixpanel
      await identify(String(payload.user.id), {
        email: payload.user.email,
        name: payload.user.name || null,
        username: payload.user.username || null,
        $email: payload.user.email,  // Mixpanel special property
        $name: (payload.user.name || payload.user.display_name || null) as string,  // Mixpanel special property
        created_at: payload.user.created_at || null,
        is_google_account: !!payload.user.google_user_id
      })

      return true
    } catch (err) {
      error.value = err instanceof Error ? err.message : 'Login failed'
      throw err
    }
  }

  const logout = async () => {
    try {
      // Call backend logout endpoint to track the event
      if (token.value) {
        await api.post('/auth/logout', null, {
          headers: { Authorization: `Bearer ${token.value}` }
        })
      }

      setAuthToken(null)
      setCurrentUser(null)
      setAuthenticated(false)
      localStorage.removeItem('token')
      return true
    } catch (err) {
      error.value = err instanceof Error ? err.message : 'Logout failed'
      throw err
    }
  }

  const restoreSession = async (savedToken: string) => {
    try {
      const response = await api.get('/auth/me', {
        headers: { Authorization: `Bearer ${savedToken}` }
      })
      
      if (response.data) {
        setCurrentUser(response.data)
        setAuthenticated(true)

        // Identify user in Mixpanel on session restore
        await identify(String(response.data.id), {
          email: response.data.email,
          name: response.data.name || null,
          username: response.data.username || null,
          $email: response.data.email,  // Mixpanel special property
          $name: (response.data.name || response.data.display_name || null) as string,  // Mixpanel special property
          created_at: response.data.created_at || null,
          is_google_account: !!response.data.google_user_id
        })
      }
    } catch (err) {
      error.value = err instanceof Error ? err.message : 'Session restoration failed'
      throw err
    }
  }

  const createAuthState = async (): Promise<string> => {
    try {
      const state = generateRandomString(32)
      localStorage.setItem('googleAuthState', state)
      return state
    } catch (err) {
      error.value = 'Failed to create auth state'
      throw new Error('Failed to create auth state')
    }
  }

  const googleLogin = async (payload: GoogleLoginPayload): Promise<GoogleLoginResult> => {
    try {
      loading.value = true
      error.value = null

      const response = await api.post('/auth/google', {
        credential: payload.credential,
        state: payload.state,
        validate_only: false  // Always complete auth
      })

      if (!response.data) {
        throw new Error('No response data from Google authentication')
      }

      // If we got a token, complete the auth
      if (response.data.access_token) {
        const userResponse = await api.get('/auth/me', {
          headers: {
            'Authorization': `Bearer ${response.data.access_token}`
          }
        })

        if (!userResponse.data) {
          throw new Error('Failed to get user info')
        }

        await login({ user: userResponse.data, token: response.data.access_token })

        // Return success with passcode status
        return {
          success: true,
          isLinked: response.data.is_linked,
          requiresPasscode: response.data.requires_passcode,
          user: userResponse.data
        }
      }

      // If we got a validation response, return it
      return {
        success: true,
        isLinked: response.data.is_linked,
        requiresPasscode: response.data.requires_passcode,
        user: response.data.user
      }
    } catch (err) {
      error.value = err instanceof Error ? err.message : 'Google login failed'
      return { success: false, error: error.value }
    } finally {
      loading.value = false
    }
  }

  const completeGoogleAuth = async (): Promise<GoogleLoginResult> => {
    try {
      if (!pendingGoogleAuth.value) {
        throw new Error('No pending Google authentication')
      }

      loading.value = true
      error.value = null

      const response = await api.post('/auth/google', {
        credential: pendingGoogleAuth.value.credential,
        state: pendingGoogleAuth.value.state,
        validate_only: false
      })

      if (!response.data) {
        throw new Error('No response data from Google authentication')
      }

      const { access_token, is_linked, requires_passcode } = response.data
      
      const userResponse = await api.get('/auth/me', {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })

      if (!userResponse.data) {
        throw new Error('Failed to get user info')
      }

      await login({ user: userResponse.data, token: access_token })
      
      // Clear pending Google auth after successful login
      pendingGoogleAuth.value = null
      
      return { 
        success: true, 
        isLinked: is_linked,
        requiresPasscode: requires_passcode,
        user: userResponse.data
      }
    } catch (err) {
      error.value = err instanceof Error ? err.message : 'Google login failed'
      return { success: false, error: error.value }
    } finally {
      loading.value = false
    }
  }

  const loginWithEmail = async (payload: EmailLoginPayload) => {
    try {
      loading.value = true
      error.value = null

      // Format request as OAuth2 form data
      const formData = new URLSearchParams()
      formData.append('username', payload.email)  // OAuth2 expects 'username' field
      formData.append('password', payload.password)

      // Send the request with form data
      const response = await api.post('/auth/token', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })

      if (!response.data) {
        throw new Error('No response data from server')
      }

      const { access_token } = response.data

      // Get user info with the new token
      const userResponse = await api.get('/auth/me', {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })

      if (!userResponse.data) {
        throw new Error('Failed to get user info')
      }

      // Check if this is a Google account
      if (userResponse.data.google_user_id) {
        loading.value = false
        return { googleAccount: true }
      }

      await login({ user: userResponse.data, token: access_token })
      
      // Clear loading state after successful login
      loading.value = false
      return { success: true }
    } catch (err: any) {
      // Clear any existing auth state on login failure
      setAuthToken(null)
      setCurrentUser(null)
      setAuthenticated(false)
      localStorage.removeItem('token')

      // Handle specific error cases
      if (err.response?.status === 401) {
        const funnyMessages = [
          "Email address, password or both are incorrect. That's okay, try again! 🔄",
          "Hmm... either this account doesn't exist yet or the password is playing hide and seek. Maybe try signing up? 🕵️",
          "Oops! Your login attempt went about as well as a penguin trying to fly. Need to sign up first? 🐧",
          "Well, this is awkward... like showing up to a party on the wrong day. Want to sign up instead? 🎉",
          "Houston, we have a problem! Either this account is shy or the password needs a pep talk. 🚀",
          "404: Success not found! But hey, have you considered signing up? It's like login, but for first-timers! ✨"
        ]
        const randomMessage = funnyMessages[Math.floor(Math.random() * funnyMessages.length)]
        error.value = randomMessage
      } else {
        error.value = err instanceof Error ? err.message : 'Login failed'
      }
      loading.value = false
      return { error: error.value }
    }
  }

  const cleanLogout = async () => {
    try {
      isCleanLogout.value = true;
      setAuthToken(null);
      setCurrentUser(null);
      setAuthenticated(false);
      localStorage.removeItem('token');
      return true;
    } catch (err) {
      error.value = err instanceof Error ? err.message : 'Logout failed';
      throw err;
    }
  };

  return {
    // State
    isAuthenticated,
    token,
    loading,
    initialized,
    error,
    apiVersion,
    currentUser,
    isCleanLogout,
    pendingGoogleAuth,

    // Getters
    isLoading,
    isInitialized,
    getError,
    getToken,
    getCurrentUser,
    getIsCleanLogout,

    // Actions
    initialize,
    login,
    logout,
    cleanLogout,
    setAuthToken,
    setAuthenticated,
    setCurrentUser,
    setInitialized,
    restoreSession,
    createAuthState,
    googleLogin,
    loginWithEmail,
    refreshToken,
    completeGoogleAuth,
  }
}) 