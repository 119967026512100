import { logger } from '@/utils/logger';

const logOptions = { module: 'TokenStorage' };

/**
 * Token storage utility functions
 */

/**
 * Store the authentication token in localStorage
 * @param token - The authentication token to store
 */
export const setToken = (token: string): void => {
  logger.debug('Storing authentication token', {
    ...logOptions,
    metadata: { tokenExists: !!token }
  });
  localStorage.setItem('token', token);
};

/**
 * Retrieve the authentication token from localStorage
 * @returns The stored token or null if not found
 */
export const getToken = (): string | null => {
  const token = localStorage.getItem('token');
  logger.debug('Retrieved authentication token', {
    ...logOptions,
    metadata: { tokenExists: !!token }
  });
  return token;
};

/**
 * Remove the authentication token from localStorage
 */
export const removeToken = (): void => {
  logger.debug('Removing authentication token', logOptions);
  localStorage.removeItem('token');
}; 