import { AppRouteRecord } from '../types'

const badgeRoutes: AppRouteRecord[] = [
  {
    path: '/badge-generator',
    name: 'BadgeGenerator',
    component: () => import('@/pages/BadgeGeneratorPage.vue'),
    meta: {
      title: 'Badge Generator',
      requiresAuth: true
    }
  },
  {
    path: '/badges/:slug',
    name: 'BadgeDetail',
    component: () => import('@/pages/BadgeDetailPage.vue'),
    meta: {
      title: 'Badge Details',
      public: true // Allow public access
    }
  }
]

export default badgeRoutes 