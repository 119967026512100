import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useUserStore } from './user';
import { useToastService } from '@/services/toast';
import { isDebugMode } from '@/config';
import { logger } from '@/utils/logger';
import api from '@/utils/api';
import type { ScheduleData } from '@/types/user';

export interface SettingsState {
  workingDays: number[];
  workingHoursStart: string;
  workingHoursEnd: string;
  timezone: string;
  isLoading: boolean;
  error: string | null;
  originalWorkingDays: number[];
  originalWorkingHoursStart: string;
  originalWorkingHoursEnd: string;
  originalTimezone: string;
  theme: string;
  originalTheme: string;
}

export const useSettingsStore = defineStore('settings', () => {
  const userStore = useUserStore();
  const toastService = useToastService();

  // Initialize state with theme from localStorage if available
  const initialTheme = localStorage.getItem('user_theme') || 'default';
  
  const state = ref<SettingsState>({
    workingDays: [],
    workingHoursStart: '09:00',
    workingHoursEnd: '17:00',
    timezone: 'US/Pacific',
    isLoading: false,
    error: null,
    originalWorkingDays: [],
    originalWorkingHoursStart: '09:00',
    originalWorkingHoursEnd: '17:00',
    originalTimezone: 'US/Pacific',
    theme: initialTheme,
    originalTheme: initialTheme
  });

  // Add utility function for working days
  const parseWorkingDays = (workingDays: any): number[] => {
    if (isDebugMode()) {
      console.log('[SettingsStore] parseWorkingDays input:', workingDays, 'type:', typeof workingDays);
    }

    if (!workingDays) return [];
    
    try {
      if (Array.isArray(workingDays)) {
        const parsed = workingDays.map(Number)
          .filter(n => !isNaN(n) && n >= 1 && n <= 7)
          .sort((a, b) => a - b);
        if (isDebugMode()) {
          console.log('[SettingsStore] Parsed array input:', parsed);
        }
        return parsed;
      }
      
      if (typeof workingDays === 'string') {
        const parsed = workingDays.split(',')
          .map(s => s.trim())
          .map(Number)
          .filter(n => !isNaN(n) && n >= 1 && n <= 7)
          .sort((a, b) => a - b);
        if (isDebugMode()) {
          console.log('[SettingsStore] Parsed string input:', parsed);
        }
        return parsed;
      }
      
      if (isDebugMode()) {
        console.log('[SettingsStore] Invalid working days format:', workingDays);
      }
      return [];
    } catch (err) {
      console.error('[SettingsStore] Error parsing working days:', err);
      return [];
    }
  };

  // Initialize from user store
  const initializeFromUser = () => {
    const currentUser = userStore.currentUser;
    if (currentUser) {
      if (isDebugMode()) {
        console.log('[SettingsStore] Initializing from user:', currentUser);
      }

      // Handle working days
      if (currentUser.working_days) {
        if (isDebugMode()) {
          console.log('[SettingsStore] Raw working days from user:', currentUser.working_days);
        }

        // Parse and sort working days
        const days = parseWorkingDays(currentUser.working_days);
        const sortedDays = [...days].sort((a, b) => a - b);

        if (isDebugMode()) {
          console.log('[SettingsStore] Parsed working days:', days);
          console.log('[SettingsStore] Sorted working days:', sortedDays);
        }

        // Update both current and original state
        state.value.workingDays = sortedDays;
        state.value.originalWorkingDays = [...sortedDays];
        
        if (isDebugMode()) {
          console.log('[SettingsStore] Working days set in store:', {
            current: state.value.workingDays,
            original: state.value.originalWorkingDays
          });
        }
      } else {
        // Reset working days if none provided
        state.value.workingDays = [];
        state.value.originalWorkingDays = [];
      }

      // Handle working hours
      if (currentUser.working_hours_start) {
        const start = currentUser.working_hours_start.slice(0, 5);
        state.value.workingHoursStart = start;
        state.value.originalWorkingHoursStart = start;
        if (isDebugMode()) {
          console.log('[SettingsStore] Working hours start from DB:', start);
        }
      }
      if (currentUser.working_hours_end) {
        const end = currentUser.working_hours_end.slice(0, 5);
        state.value.workingHoursEnd = end;
        state.value.originalWorkingHoursEnd = end;
        if (isDebugMode()) {
          console.log('[SettingsStore] Working hours end from DB:', end);
        }
      }

      // Handle timezone
      if (currentUser.timezone) {
        state.value.timezone = currentUser.timezone;
        state.value.originalTimezone = currentUser.timezone;
        if (isDebugMode()) {
          console.log('[SettingsStore] Timezone from DB:', state.value.timezone);
        }
      }

      // Handle theme
      if (currentUser.theme) {
        state.value.theme = currentUser.theme;
        state.value.originalTheme = currentUser.theme;
        // Save theme to localStorage for faster loading on refresh
        localStorage.setItem('user_theme', currentUser.theme);
      }

      if (isDebugMode()) {
        console.log('[SettingsStore] Initialization complete:', {
          workingDays: state.value.workingDays,
          originalWorkingDays: state.value.originalWorkingDays,
          workingHoursStart: state.value.workingHoursStart,
          workingHoursEnd: state.value.workingHoursEnd,
          timezone: state.value.timezone
        });
      }
    }
  };

  // Getters
  const workingDays = computed(() => state.value.workingDays);
  const workingHoursStart = computed(() => state.value.workingHoursStart);
  const workingHoursEnd = computed(() => state.value.workingHoursEnd);
  const timezone = computed(() => state.value.timezone);
  const isLoading = computed(() => state.value.isLoading);
  const error = computed(() => state.value.error);

  // Original values getters
  const originalWorkingDays = computed(() => state.value.originalWorkingDays);
  const originalWorkingHoursStart = computed(() => state.value.originalWorkingHoursStart);
  const originalWorkingHoursEnd = computed(() => state.value.originalWorkingHoursEnd);
  const originalTimezone = computed(() => state.value.originalTimezone);

  const isModified = computed(() => {
    if (isDebugMode()) {
      console.log('[SettingsStore] Checking modifications:', {
        workingDays: state.value.workingDays,
        originalWorkingDays: state.value.originalWorkingDays,
        workingHoursStart: state.value.workingHoursStart,
        originalWorkingHoursStart: state.value.originalWorkingHoursStart,
        workingHoursEnd: state.value.workingHoursEnd,
        originalWorkingHoursEnd: state.value.originalWorkingHoursEnd,
        timezone: state.value.timezone,
        originalTimezone: state.value.originalTimezone
      });
    }

    const workingDaysChanged = JSON.stringify([...state.value.workingDays].sort()) !== 
                              JSON.stringify([...state.value.originalWorkingDays].sort());
    const hoursChanged = state.value.workingHoursStart !== state.value.originalWorkingHoursStart ||
                        state.value.workingHoursEnd !== state.value.originalWorkingHoursEnd;
    const timezoneChanged = state.value.timezone !== state.value.originalTimezone;

    const isModified = workingDaysChanged || hoursChanged || timezoneChanged;

    if (isDebugMode()) {
      console.log('[SettingsStore] Modification check result:', {
        workingDaysChanged,
        hoursChanged,
        timezoneChanged,
        isModified
      });
    }

    return isModified;
  });

  // Actions
  const isWorkingDay = computed(() => (day: number) => {
    if (isDebugMode()) {
      console.log(`[SettingsStore] Checking if day ${day} is a working day. Current working days:`, state.value.workingDays);
    }
    return state.value.workingDays.includes(day);
  });

  const updateWorkingDays = async (days: number[]) => {
    try {
      state.value.isLoading = true;
      state.value.error = null;
      
      // Convert array of numbers to comma-separated string
      const workingDaysString = days.join(',');
      
      // Make API call to update working days
      await api.put('/users/me', { working_days: workingDaysString });
      state.value.workingDays = days;
      
      logger.debug('Working days updated in store', {
        module: 'SettingsStore',
        metadata: { workingDays: days }
      });
    } catch (err) {
      state.value.error = err instanceof Error ? err.message : 'Failed to update working days';
      logger.error('Failed to update working days in store', {
        module: 'SettingsStore',
        metadata: { error: err }
      });
      throw err;
    } finally {
      state.value.isLoading = false;
    }
  };

  const updateWorkingHours = async (start: string, end: string) => {
    try {
      state.value.isLoading = true;
      state.value.error = null;
      
      // Format time strings to HH:MM format
      const formatTime = (time: string) => {
        const [hours, minutes] = time.split(':');
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
      };
      
      // Make API call to update working hours
      await api.put('/users/me', {
        working_hours_start: formatTime(start),
        working_hours_end: formatTime(end)
      });
      
      state.value.workingHoursStart = start;
      state.value.workingHoursEnd = end;
      
      logger.debug('Working hours updated in store', {
        module: 'SettingsStore',
        metadata: { start, end }
      });
    } catch (err) {
      state.value.error = err instanceof Error ? err.message : 'Failed to update working hours';
      logger.error('Failed to update working hours in store', {
        module: 'SettingsStore',
        metadata: { error: err }
      });
      throw err;
    } finally {
      state.value.isLoading = false;
    }
  };

  const updateTimezone = async (timezone: string) => {
    try {
      state.value.isLoading = true;
      state.value.error = null;
      
      // Make API call to update timezone
      await api.put('/users/me', { timezone });
      state.value.timezone = timezone;
      
      logger.debug('Timezone updated in store', {
        module: 'SettingsStore',
        metadata: { timezone }
      });
    } catch (err) {
      state.value.error = err instanceof Error ? err.message : 'Failed to update timezone';
      logger.error('Failed to update timezone in store', {
        module: 'SettingsStore',
        metadata: { error: err }
      });
      throw err;
    } finally {
      state.value.isLoading = false;
    }
  };

  const toggleWorkingDay = async (day: number) => {
    try {
      state.value.isLoading = true;
      state.value.error = null;

      const currentDays = [...state.value.workingDays];
      const dayIndex = currentDays.indexOf(day);

      if (dayIndex === -1) {
        currentDays.push(day);
      } else {
        currentDays.splice(dayIndex, 1);
      }

      // Sort days to maintain consistent order
      currentDays.sort((a, b) => a - b);

      // Update working days through the existing method
      await updateWorkingDays(currentDays);

      logger.debug('Working day toggled in store', {
        module: 'SettingsStore',
        metadata: { day, currentDays }
      });
    } catch (err) {
      state.value.error = err instanceof Error ? err.message : 'Failed to toggle working day';
      logger.error('Failed to toggle working day in store', {
        module: 'SettingsStore',
        metadata: { error: err }
      });
      throw err;
    } finally {
      state.value.isLoading = false;
    }
  };

  const updateSchedule = async (scheduleData: ScheduleData) => {
    try {
      state.value.isLoading = true;
      state.value.error = null;
      
      if (isDebugMode()) {
        console.log('[SettingsStore] Updating schedule with:', scheduleData);
      }

      // Format time strings to HH:MM format
      const formatTime = (time: string) => {
        if (!time.includes(':')) return time;
        return time.split(':').slice(0, 2).join(':');
      };

      // Prepare the payload
      const payload = {
        working_days: [...scheduleData.working_days].sort((a, b) => a - b).join(','),
        working_hours_start: formatTime(scheduleData.working_hours_start),
        working_hours_end: formatTime(scheduleData.working_hours_end),
        timezone: scheduleData.timezone
      };

      if (isDebugMode()) {
        console.log('[SettingsStore] Sending payload to API:', payload);
      }

      // Make API call to update schedule
      const response = await api.put('/users/me', payload);

      if (isDebugMode()) {
        console.log('[SettingsStore] API response:', response.data);
      }

      // Update local state with sorted working days
      const sortedWorkingDays = [...scheduleData.working_days].sort((a, b) => a - b);
      state.value.workingDays = sortedWorkingDays;
      state.value.workingHoursStart = scheduleData.working_hours_start;
      state.value.workingHoursEnd = scheduleData.working_hours_end;
      state.value.timezone = scheduleData.timezone;

      // Update original values
      state.value.originalWorkingDays = [...sortedWorkingDays];
      state.value.originalWorkingHoursStart = scheduleData.working_hours_start;
      state.value.originalWorkingHoursEnd = scheduleData.working_hours_end;
      state.value.originalTimezone = scheduleData.timezone;
      
      // Force refresh user data to ensure sync
      await userStore.refreshUser();
      
      logger.debug('Schedule updated in store', {
        module: 'SettingsStore',
        metadata: { 
          scheduleData,
          currentState: {
            workingDays: state.value.workingDays,
            originalWorkingDays: state.value.originalWorkingDays
          }
        }
      });

      toastService.showSuccessToast('Working schedule updated successfully');

      if (isDebugMode()) {
        console.log('[SettingsStore] Update complete, new state:', {
          workingDays: state.value.workingDays,
          originalWorkingDays: state.value.originalWorkingDays
        });
      }

    } catch (err) {
      state.value.error = err instanceof Error ? err.message : 'Failed to update schedule';
      logger.error('Failed to update schedule in store', {
        module: 'SettingsStore',
        metadata: { error: err }
      });

      toastService.showErrorToast(state.value.error);
      throw err;
    } finally {
      state.value.isLoading = false;
    }
  };

  const setWorkingHoursStart = (value: string) => {
    if (isDebugMode()) {
      console.log('[SettingsStore] Setting working hours start:', value);
    }
    state.value.workingHoursStart = value;
  };

  const setWorkingHoursEnd = (value: string) => {
    if (isDebugMode()) {
      console.log('[SettingsStore] Setting working hours end:', value);
    }
    state.value.workingHoursEnd = value;
  };

  const setTimezone = (value: string) => {
    if (isDebugMode()) {
      console.log('[SettingsStore] Setting timezone:', value);
    }
    state.value.timezone = value;
  };

  // Add method to update theme
  const updateTheme = async (theme: string) => {
    state.value.isLoading = true;
    state.value.error = null;

    try {
      const response = await api.put('/users/settings', {
        theme
      });

      if (response.status === 200) {
        state.value.theme = theme;
        state.value.originalTheme = theme;
        // Save theme to localStorage for faster loading on refresh
        localStorage.setItem('user_theme', theme);
        toastService.showSuccessToast('Theme updated successfully');
      } else {
        state.value.error = 'Failed to update theme';
        toastService.showErrorToast('Failed to update theme');
      }
    } catch (error) {
      state.value.error = 'An error occurred while updating theme';
      toastService.showErrorToast('An error occurred while updating theme');
      logger.error('Error updating theme', {
        module: 'SettingsStore',
        metadata: { error }
      });
    } finally {
      state.value.isLoading = false;
    }
  };

  // Add method to set theme locally (without API call)
  const setTheme = (theme: string) => {
    if (isDebugMode()) {
      console.log('[SettingsStore] Setting theme:', theme);
    }
    state.value.theme = theme;
    // Save theme to localStorage for faster loading on refresh
    localStorage.setItem('user_theme', theme);
  };

  // Add computed property to check if theme has been modified
  const isThemeModified = computed(() => {
    return state.value.theme !== state.value.originalTheme;
  });

  return {
    // State
    state,
    workingDays,
    workingHoursStart,
    workingHoursEnd,
    timezone,
    isLoading,
    error,

    // Original values
    originalWorkingDays,
    originalWorkingHoursStart,
    originalWorkingHoursEnd,
    originalTimezone,

    // Getters
    isWorkingDay,
    isModified,
    isThemeModified,

    // Actions
    initializeFromUser,
    updateWorkingDays,
    updateWorkingHours,
    updateTimezone,
    toggleWorkingDay,
    updateSchedule,
    setWorkingHoursStart,
    setWorkingHoursEnd,
    setTimezone,
    updateTheme,
    setTheme
  };
}); 